<script setup lang="ts">
import dayjs from "dayjs";

const props = defineProps<{
	buttonName?: string;
	rewardEntry?: boolean;
	redeemForm?: boolean;
}>();

const { t } = useT();
const isMobileClient = useMediaQuery("(max-width: 767px)");

const {
	firstNameField,
	isLoading,
	cityField,
	addressField,
	stateField,
	phoneField,
	appInitData,
	lastNameField,
	zipCodeField,
	dateField,
	isDisabledEasy,
	visibleField,
	onSubmit
} = useUserInfo({ rewardEntry: props.rewardEntry, redeemForm: props.redeemForm });

const emit = defineEmits<{ (event: "handleSubmit"): void }>();

const maxDate = dayjs().subtract(18, "year").toDate();

const handleSubmit = (event: Event) => {
	onSubmit(event);
	emit("handleSubmit");
};

const clearPhoneError = () => {
	phoneField.errorMessage.value = "";
};
</script>

<template>
	<form class="user-form" @submit="handleSubmit">
		<div class="box-fields">
			<div v-if="visibleField('name')" class="row">
				<MInput
					v-model="firstNameField.value.value"
					:disabled="!!appInitData?.name"
					class="text-field"
					:placeholder="t('First name')"
					autocomplete="firstName"
					clear
					:invalid="firstNameField.isShowError.value"
					@blur="firstNameField.isTouched.value = true"
				/>
				<AAnimationSlide
					v-if="firstNameField.isShowError.value"
					:active="firstNameField.isShowError.value"
					:distance="4"
				>
					<AText variant="topeka" class="color-primary-60">
						{{
							firstNameField.errorType.value === "latinError"
								? t("Please use Roman letters only. Omit accents or special characters.")
								: t("Please enter your first name")
						}}
					</AText>
				</AAnimationSlide>
			</div>
			<div v-if="visibleField('surname')" class="row">
				<MInput
					v-model="lastNameField.value.value"
					:disabled="!!appInitData?.surname"
					class="text-field"
					:placeholder="t('Last name')"
					autocomplete="lastName"
					clear
					:invalid="lastNameField.isShowError.value"
					@blur="lastNameField.isTouched.value = true"
				/>
				<AAnimationSlide v-if="lastNameField.isShowError.value" :active="lastNameField.isShowError.value" :distance="4">
					<AText variant="topeka" class="color-primary-60">
						{{
							lastNameField.errorType.value === "latinError"
								? t("Please use Roman letters only. Omit accents or special characters.")
								: t("Please enter your last name")
						}}
					</AText>
				</AAnimationSlide>
			</div>
			<div v-if="visibleField('birthday')" class="row half-width">
				<MDatePicker
					v-model="dateField.value.value"
					:disabled="!!appInitData?.birthDay"
					:class="['date', { dateError: dateField.isShowError.value }]"
					autoApply
					placeholder="MM/DD/YYYY"
					:startDate="maxDate"
					:maxDate="maxDate"
					:flow="['year', 'month', 'calendar']"
					position="left"
					:teleportCenter="isMobileClient"
				/>
				<AAnimationSlide v-if="dateField.isShowError.value" :active="dateField.isShowError.value" :distance="4">
					<AText variant="topeka" class="color-primary-60">
						{{ dateField.errorMessage.value }}
					</AText>
				</AAnimationSlide>
			</div>
			<div v-if="visibleField('zip')" class="row half-width">
				<MInput
					v-model="zipCodeField.value.value"
					:disabled="!!appInitData?.zip"
					class="text-field"
					:placeholder="`${t('Zip code')}*`"
					autocomplete="zipCode"
					clear
					type="tel"
					:invalid="zipCodeField.isShowError.value"
					@blur="zipCodeField.isTouched.value = true"
				/>
				<AAnimationSlide v-if="zipCodeField.isShowError.value" :active="zipCodeField.isShowError.value" :distance="4">
					<AText variant="topeka" class="color-primary-60">
						{{ t("Please enter your zipcode") }}
					</AText>
				</AAnimationSlide>
			</div>
			<div v-if="visibleField('address')" class="row">
				<MInput
					id="address"
					v-model="addressField.value.value"
					class="text-field"
					:placeholder="`${t('Address Line 1')}*`"
					autocomplete="address"
					clear
					:invalid="addressField.isShowError.value"
					@blur="addressField.isTouched.value = true"
				/>
				<AAnimationSlide v-if="addressField.isShowError.value" :active="addressField.isShowError.value" :distance="4">
					<AText variant="topeka" class="color-primary-60">
						{{ t("Please enter your address") }}
					</AText>
				</AAnimationSlide>
			</div>
			<div v-if="visibleField('city')" class="row half-width">
				<MInput
					v-model="cityField.value.value"
					class="text-field"
					:placeholder="`${t('City')}*`"
					autocomplete="city"
					clear
					:invalid="cityField.isShowError.value"
					@blur="cityField.isTouched.value = true"
				/>
				<AAnimationSlide v-if="cityField.isShowError.value" :active="cityField.isShowError.value" :distance="4">
					<AText variant="topeka" class="color-primary-60">
						{{ t("Please enter your city") }}
					</AText>
				</AAnimationSlide>
			</div>
			<div v-if="visibleField('state')" class="row half-width">
				<MDropdown
					v-model="stateField.value.value"
					class="dropdown-field"
					placeholder="Select state"
					:modifiers="['wide']"
					@blur="stateField.isTouched.value = true"
				>
					<template #content="{ setValue }">
						<AOption v-for="(item, index) in states" :key="index" @click="setValue(item.name)">{{ item.name }}</AOption>
					</template>
				</MDropdown>
				<AAnimationSlide v-if="stateField.isShowError.value" :active="stateField.isShowError.value" :distance="4">
					<AText variant="topeka" class="color-primary-60">
						{{ t("Please select your State") }}
					</AText>
				</AAnimationSlide>
			</div>
			<div v-if="visibleField('phone')" class="row">
				<MTelInput
					v-model="phoneField.value.value"
					v-maska
					class="text-field"
					:invalid="phoneField.isShowError.value"
					data-maska="(###) ### ####"
					placeholder="(555) 555 5555"
					autocomplete="phone"
					clear
					@blur="phoneField.isTouched.value = true"
					@input="clearPhoneError"
				/>
				<AAnimationSlide v-if="phoneField.isShowError.value" :active="phoneField.isShowError.value" :distance="4">
					<AText variant="topeka" class="color-primary-60">
						{{ phoneField.errorMessage.value || t("Please provide a valid phone number") }}
					</AText>
				</AAnimationSlide>
			</div>
		</div>

		<AText variant="topeka" :modifiers="['center', 'bold']" class="row" as="div">
			{{ t("profile.security.title", { key: buttonName || t("Save") }) }}
		</AText>

		<AButton variant="outline" type="submit" size="xl" :disabled="isDisabledEasy" :modifiers="['wide']">
			<NuxtIcon v-if="isLoading" name="28/spinner" class="loading-icon" filled />
			<AText v-else variant="tanta" :modifiers="['capitalize']" class="text-tlalnepantla">
				{{ buttonName || t("Save") }}
			</AText>
		</AButton>
	</form>
</template>

<style lang="scss" scoped>
.user-form {
	display: flex;
	flex-direction: column;
	gap: 16px;

	&:deep(button:disabled) {
		opacity: 0.5;
		cursor: not-allowed;
	}

	.row {
		display: flex;
		flex-direction: column;

		&:deep(.dp__main) {
			--m-datepicker-max-width: 100%;
		}

		&:deep(.input-group[disabled="true"]) {
			--a-input-color: var(--neutral-40);
			--a-input-group-border: var(--neutral-40);
		}
	}

	.date {
		&.dateError :deep(input) {
			border: 1px solid var(--primary-60);
		}

		&:deep(.dp__input) {
			--dp-menu-background: var(--neutral-15);
			--m-datepicker-background-color: var(--neutral-15);
			--m-datepicker-border-color: var(--neutral-70);
			--m-datepicker-input-color: var(--neutral-100);
			--dp-font-size: 14px;
			font-weight: normal;
			height: 50px;

			&::placeholder {
				color: var(--neutral-70);
			}

			&:disabled {
				--m-datepicker-border-color: var(--neutral-40);
				--m-datepicker-input-color: var(--neutral-40);
				opacity: 1;
			}
		}
		&:deep(.dp__menu) {
			--dp-overlay-col-padding: 0 3px;
			.dp__overlay_cell_disabled {
				display: none;
			}
			.dp__overlay_cell {
				margin: 3px 0;
			}
			.dp__container_block {
				margin: 0;
				padding: 8px 0;
			}

			.dp__instance_calendar {
				padding-bottom: 2px;

				> .dp__menu_inner {
					padding-top: 16px;
				}
			}

			.dp__month_year_wrap {
				.dp__overlay.dp--overlay-absolute {
					padding: 4px 0;
				}
				.dp__overlay_container.dp__container_block {
					max-height: 100%;
				}
			}
		}

		&:deep(.dp--menu-wrapper) {
			box-shadow: 0 0 12px 0 #141414;

			&[style^="top: 60px"] {
				transform: translateY(calc(-100% - 64px));
			}
		}
	}

	.row-grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 8px;
	}

	.text-field {
		--a-input-color: var(--neutral-100);
		background-color: var(--neutral-15);
		height: 50px;
	}

	.dropdown-field {
		margin-right: -8px;

		&:deep(.dropdown) {
			height: 50px;
			--a-input-group-background: var(--neutral-15);

			input {
				padding: 0;
				color: var(--neutral-70);
			}

			.icon-arrow {
				border-radius: 50%;
				border: 1px solid var(--neutral-70);
				width: 24px;
				height: 24px;

				&::before {
					border-bottom: 1px solid var(--neutral-70);
					border-left: 1px solid var(--neutral-70);
				}
			}
		}

		&:deep(.popper) {
			--a-input-group-background: var(--neutral-15);
			--a-option-background: var(--neutral-15);
			--a-option-color: var(--neutral-70);

			.option {
				border-left-width: 0;

				&:first-child {
					border-top-width: 0;
					border-top-right-radius: 0;
				}

				&:last-child {
					border-bottom-width: 0;
					border-bottom-right-radius: 0;
				}
			}
		}
	}

	.loading-icon {
		font-size: 35px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.box-fields {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;

	.row {
		width: 100%;
	}

	.half-width {
		width: calc(50% - 8px);
	}
}
</style>
